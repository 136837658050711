import Vue from 'vue';

export default {
  template:"\
    <div class='membership-list'> \
      <table> \
        <thead> \
          <tr> \
            <th>User</th> \
            <th>Started</th> \
            <th>Role</th> \
            <th>Options</th> \
          </tr> \
        </thead> \
        <tr v-for='membership in memberships'> \
          <td> \
            <a :href='\"/system/users/\" + membership.user.id'>{{ membership.user.email }}</a> \
          </td> \
          <td>{{ membership.created_at }}</td> \
          <td> \
            {{ membership.role }} \
          </td> \
          <td> \
            <a v-if='canedit == true' href='#' @click.prevent='editMembership(membership.id)' class='button button-primary'>Edit</a> \
            <a v-if='candelete == true' href='#' @click.prevent='deleteMembership(membership.id)' class='button button-primary'>Delete</a> \
            <a v-if='canbecome == true' href='#' @click.prevent='becomeMembership(membership.user.id)' class='button button-primary'>Become</a> \
          </td> \
        </tr>\
      </table> \
    </div>",
  props: {
    'memberships': {
      type: Array,
      default: []
    },
    'canedit': {
      type: Boolean,
      default: false
    },
    'candelete': {
      type: Boolean,
      default: false
    },
    'canbecome': {
      type: Boolean,
      default: false
    }
  },
  methods: {
    deleteMembership: function(id){
      this.$emit('deletemember', id);
    },
    editMembership: function(id){
      this.$emit('editmember', id);
    },
    becomeMembership: function(id){
      this.$emit('becomemember', id);
    }
  },
  created: function(){

  }
}