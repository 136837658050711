import Vue from 'vue';
require('es6-promise').polyfill();
const axios = require('axios');
import MembershipList from '../superadmin/membership-list';
import MembershipForm from '../superadmin/membership-form';
import ClipboardJS from 'clipboard';

$(document).on('ready', init);

function updateProjectSlug(){
  var val = this.value;
  var targetSelector = this.getAttribute('data-text');
  $(targetSelector).text(val);
}

function init(){

  let clipboard = new ClipboardJS('.clipboard');

  clipboard.on('success', function(e) {
    debugger
    $('.note .content').text('Copied to clipboard: ' + e.text );
    $('.note').addClass('active');
    setTimeout(function(){
      $('.note').removeClass('active');
    }, 2000);
  });

  $('.project-form input.url-slug').on('keyup', updateProjectSlug);

  $('.combine-check-box').on('click', function(ev){
    var checkCount = 0;
    var checked = []
    $('.combine-check-box').each(function(i,el){
      if(el.checked){
        checkCount++;
        checked.push(el.value)
      }
    })
    if(checkCount > 1){
      $('.combine-button').val('Combine results')
      $('.combine-button').removeAttr('disabled');
    } else {
      $('.combine-button').val('Combine results')
      $('.combine-button').attr('disabled', 'disabled');
    }
  })

  Vue.prototype.$csrfToken = document.querySelectorAll('meta[name="csrf-token"]')[0].content;
  if($('#projectdashboard').length > 0){
    new Vue({
      el: '#projectdashboard',
      components: {
        'membership-form': MembershipForm,
        'membership-list': MembershipList
      },
      data: {
        project: projectData.project,
        leaderships: projectData.leaderships,
        showModal: false
      },
      methods: {
        onDeleteLeadership: function(leadershipId){
          if(confirm('Are you sure? This action will permanently delete the leadership and cannot be undone.') == false){
            return;
          }
          axios(
            {
              method: 'delete',
              url: '/admin/projects/' + this.project.id + '/leaderships/' + leadershipId + '.json',
              headers: {'X-CSRF-Token': this.$csrfToken}
            }
          ).then(
            function(response){
              if(typeof response.data.leaderships != "undefined"){
                this.leaderships = response.data.leaderships;
              }
            }.bind(this)
          )
        },
        onAssignLeadership: function(candidate){
          axios.post('/admin/projects/' + this.project.id + '/leaderships', { user: candidate, project_id: this.project.id}, { headers: {'X-CSRF-Token': this.$csrfToken} }
          ).then(
            function(response){
              if(typeof response.data.leaderships != "undefined"){
               this.leaderships = response.data.leaderships;
              }
            }.bind(this)
          ).catch(
            function(err){
              console.log(err)
            }
          )
        },
      }
    })
  }
}