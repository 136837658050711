<template>
  <div class='module-configuration'>
    <div class="modules">
      <div class="not-selected pod">
        <h3>Available</h3>
        <ul>
          <li v-for='(item) in notSelected'>
            {{item.name}}
            <a @click.prevent='addModule(item, "position_b")'>Before</a>
            <a @click.prevent='addModule(item, "position_a")'>After</a>
            <input type='hidden' value='1' :name='"project[module_configs_attributes][" + item.index + "][_destroy]"'>
            <input type='hidden' :value='item.id' :name='"project[module_configs_attributes][" + item.index + "][id]"'>
          </li>
        </ul>
      </div>
      <div class="selected">
        <div class="pod">
          <h3>Before Pulse</h3>
          <ul class='selected-before'>
            <li v-if='item.position == "position_b"' v-for='item, i in selectedItems'>
              {{item.name}}
              <a @click.prevent='removeModule(item)'>Remove</a>
              <input type='hidden' :value='item.id' :name='"project[module_configs_attributes][" + item.index + "][id]"'>
              <input type='hidden' :value='item.mod_id' :name='"project[module_configs_attributes][" + item.index + "][mod_id]"'>
              <input type='hidden' value='0' :name='"project[module_configs_attributes][" + item.index + "][_destroy]"'>
              <input type='hidden' :value='item.position' :name='"project[module_configs_attributes][" + item.index + "][position]"'>
              <input type='hidden' :value='i' :name='"project[module_configs_attributes][" + item.index + "][order]"'>
            </li>
          </ul>
        </div>
        <div class="pod main-pulse-pod">
          <h3>Main Pulse</h3>
          <ul class='selected-after'>
            <li>Overview, Be, Do & Connect</li>
          </ul>
        </div>

        <div class="pod ">
          <h3>After Pulse</h3>
          <ul class='selected-after'>
            <li v-if='item.position == "position_a"' v-for='item, i in selectedItems'>
              {{item.name}}
              <a @click.prevent='removeModule(item)'>Remove</a>
              <input type='hidden' :value='item.id' :name='"project[module_configs_attributes][" + item.index + "][id]"'>
              <input type='hidden' :value='item.mod_id' :name='"project[module_configs_attributes][" + item.index + "][mod_id]"'>
              <input type='hidden' value='0' :name='"project[module_configs_attributes][" + item.index + "][_destroy]"'>
              <input type='hidden' :value='item.position' :name='"project[module_configs_attributes][" + item.index + "][position]"'>
              <input type='hidden' :value='i' :name='"project[module_configs_attributes][" + item.index + "][order]"'>
            </li>
          </ul>
        </div>

        <div class="pod demographics-pod">
          <h3>Demographics</h3>
        </div>

      </div>

    </div>

  </div>
</template>
<script>
  export default {
    props: ['init'],
    data () {
      return {
        modules: [],
        available: [],
        notSelected: [],
        selectedItems:[]
      }
    },
    methods: {
      lookupModById (id) {
        let mod = null
        this.available.forEach( (el, i) => {
          if (el.id === id) {
            mod = el
          }
        })
        return mod
      },
      addModule (item, position) {
        let idx = this.notSelected.indexOf(item);
        item.position = position
        this.notSelected.splice(idx, 1)
        this.selectedItems.push(item)
      },
      removeModule (item) {
        let idx = this.selectedItems.indexOf(item);
        this.selectedItems.splice(idx, 1)
        this.notSelected.push(item)
      }
    },
    created () {
      let json = JSON.parse(this.init)
      for (let prop in json) {
        this[prop] = json[prop]
      }
      this.modules.forEach((el, i) => {
        let mod = this.lookupModById(el.mod_id)
        el.index = i
        if(mod){
          el.name = mod.name
        }
        if(el.id){
          this.selectedItems.push(el)
        } else {
          this.notSelected.push(el)
        }
      })
    }
  }
</script>
<style lang='scss' scoped>
  .modules {
    display: flex;
    & > div {

      flex: 1 1 50%;
    }
    & > div + div {
      margin-left: 1em;
    }
    .pod {
      padding:1em;
      background-color: #6BBDE0;
      & + .pod {
        margin-top:1em;
      }
    }
    .not-selected.pod {
      background-color:white;
    }
    .main-pulse-pod {
      background-color: #0C2163;
      color:white;
    }
    .demographics-pod {
      background-color: #A14771;
      color:white;
    }
    h3 {
      font-weight:bold;
    }
  }
</style>