import Trix from 'trix'
import SimpleLightbox from 'simple-lightbox'


let lightboxes = [];

function openLightbox(lightboxIndex, imageIndex){
  lightboxes[lightboxIndex].showPosition(imageIndex);
}

document.addEventListener('DOMContentLoaded', function(event){

  document.querySelectorAll('.trix-content section').forEach(function(el, i)
  {
    let images = []

    el.querySelectorAll('figure img').forEach(function(img, j){
      images.push(img.src)
      img.dataset.lightbox = i.toString();
      img.addEventListener('click', ()=>{
        openLightbox(i, j);
      })
    })

    let lightbox = new SimpleLightbox({
      items: images
    });
    lightboxes.push(lightbox)
  })

})

function doSomething(event){
  console.log(event)
}

Trix.config.blockAttributes.modal = {
  tagName: "section", style: {backgroundColor: '#cccccc'}
}


addEventListener("trix-initialize", event => {
  const buttonHTML  = '<button type="button" class="trix-button" data-trix-attribute="modal" title="Custom Section">Modal image</button>'
  const groupElement = event.target.toolbarElement.querySelector(".trix-button-group--block-tools")
  groupElement.insertAdjacentHTML("beforeend", buttonHTML)
})