export default class {
  constructor (el) {
    this.element = el;
    this.targetSelector = el.getAttribute('data-expand')
    this.target = document.querySelector(this.targetSelector)
    this.targetHeight = this.target.scrollHeight;
    this.closeTarget();
    
    if(!this.target){
      throw new Error('No expandable target matches ' + this.targetSelector)
    }
    this.closed = true;
    this.addEvents()
  }
  addEvents () {
    this.element.addEventListener('click', this.toggle.bind(this))
  }
  toggle (e) {
    e.preventDefault();
    if(this.closed){
      this.openTarget()
    } else {
      this.closeTarget()
    }
    this.closed = !this.closed
  }
  closeTarget () {
    this.addStyle('height', '0px');
  }
  openTarget () {
    this.addStyle('height', this.targetHeight + 'px');
  }
  addStyle(prop, val){
    this.target.style[prop] = val;
  }
}