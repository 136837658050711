import Vue from 'vue';
const _ = require('lodash');

export default {
  template: "\
    <div class='autocomplete'> \
      <fieldset> \
      <slot name='legend'></slot> \
      <div class='field'> \
      <div class='control'> \
      <input type='text' class='input' v-on:keyup='onKeyup' v-model='search'> \
      </div></div> \
      </fieldset> \
    </div> \
  ",
  data: function(){
    return {
      search: '',
      candidates: [],
      hasNoCandidates: false
    }
  },
  props: ['url'],
  methods: {
    onKeyup: _.debounce( function(){

      this.$http.post(this.url, 
          { search: this.search },
          { headers: {'X-CSRF-Token': this.$csrfToken} }
      ).then(this.loadCandidates);
              
    }, 500)
  },
  created: function(){
    this.loadCandidates = function(response){
      this.hasNoCandidates = response.data.results.length > 0 ? false : true;
      this.candidates = response.data.results;
      this.$emit('onresults', response.data)
    }.bind(this)
  }
}