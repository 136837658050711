function initOtherField(querySelector, handler){
  let el = document.querySelector(querySelector)
  if (!el){
    return
  }
  onOtherFieldChange.bind(el)()
  el.addEventListener('change', onOtherFieldChange)
}

function initSignupForm(){
  initOtherField('#user_organisation_company_intent', onOtherFieldChange)
  initOtherField('#user_organisation_use_scale', onOtherFieldChange)
}

function onOtherFieldChange(){
  let otherSelector = this.dataset.other
  let otherValue = this.dataset.otherCondition
  let otherEl = document.querySelector(otherSelector)
  if (this.selectedOptions[0].value == otherValue) {
    otherEl.classList.remove('hidden')
  } else {
    otherEl.classList.add('hidden')
  }
}


document.addEventListener('DOMContentLoaded', function () {
  if (document.querySelector('#new_user')){
    initSignupForm();
  }
})