export default class {
  constructor (el) {
    this.toggleElement = el
    let targetSelector = this.toggleElement.getAttribute('data-modal-target')
    this.target = document.querySelector(targetSelector)
    this.toggleElement.addEventListener('click', this.toggle.bind(this))
  }
  toggle (ev){
    this.target.classList.toggle('is-active');
  }
}