import { bind } from './common/helpers';

export default class {
  constructor($el) {
    this.mouse_moved = bind(this.mouse_moved, this);
    this.update = bind(this.update, this);

    this.$el = $el;
    this.$foreground = this.$el.find('[data-parallax-foreground]');
    this.$middleground = this.$el.find('[data-parallax-middleground]');
    this.$background = this.$el.find('[data-parallax-background]');
    this.mode = this.$el.data('parallax');
    this.parallax_strength = 20;
    this.parallax_middleground_amount = 2.5;
    this.parallax_background_amount = 4;
    this.pos = 0;
    this.inverse_movement = true;

    if (this.mode == 'auto') {
      this.auto();
    } else if (this.mode == 'mouse') {
      this.events();
    }
  }

  events() {
    $(document).on('mousemove', this.mouse_moved);
  }

  auto() {
    var render = this.update;

    (function loop(){
      requestAnimationFrame(loop);
      render();
    })();
  }

  mouse_moved(e) {
    // get half of the document size, as the movement will be measured
    // from the center
    var half = $(document).width() / 2;
    // one % of the half way point
    var percent = half / 100;
    // minus the mouse position from half, this should give us a range between
    // -600 and +600 if the screen is 1200 wide
    var position = e.pageX - half;
    // calculate the percent position, so if mouse is at +300
    // on a 1200 wide screen, it should be 50% across the right half
    var position_percent = Math.round(position / percent);
    // the final number, should range between -1 and +1
    var position_range = position_percent / 100;

    if (this.inverse_movement) {
      if (position_range > 0) {
        position_range = position_range - (position_range * 2);
      } else if (position_range < 0) {
        position_range = Math.abs(position_range);
      }
    }

    this.render(position_range);
  }

  update() {
    this.pos += 0.01;
    this.render( parseFloat(Math.sin(this.pos).toFixed(2)) );
  }

  render(position) {
    var foreground_pos = position * this.parallax_strength;
    var middleground_pos = foreground_pos * this.parallax_middleground_amount;
    var background_pos = foreground_pos * this.parallax_background_amount;

    this.$foreground.css({ left: foreground_pos });
    this.$middleground.css({ left: middleground_pos });
    this.$background.css({ left: background_pos });
  }
}
