import 'nodelist-foreach-polyfill';
import Expandable from './expandable.js';
import ModalToggle from './modal-toggle.js';
import FilterLinks from '../admin/filter-links.vue';
import ModuleConfiguration from '../admin/module-configuration.vue';
import DemographicOptions from '../admin/demographic-options.vue'
import Vue from 'vue';
import axios from 'axios'

document.addEventListener('DOMContentLoaded', function () {
  var passwordResetButton
  if(passwordResetButton = document.querySelector('a#request-password')){
    passwordResetButton.addEventListener('click', function(ev){
      ev.preventDefault();
      let url = passwordResetButton.dataset.url
      axios.get(url).then(function(resp){
        let resetUrl = 'https://app.happinesspulse.org/users/password/edit?reset_password_token=' + resp.data.token
        document.querySelector('p#reset-url').innerText = resetUrl
      })
    })
    return false
  }

  // Get all "navbar-burger" elements
  var $navbarBurgers = Array.prototype.slice.call(document.querySelectorAll('.navbar-burger'), 0);

  // Check if there are any navbar burgers
  if ($navbarBurgers.length > 0) {

    // Add a click event on each of them
    $navbarBurgers.forEach(function ($el) {
      $el.addEventListener('click', function () {

        // Get the target from the "data-target" attribute
        var target = $el.dataset.target;
        var $target = document.getElementById(target);

        // Toggle the class on both the "navbar-burger" and the "navbar-menu"
        $el.classList.toggle('is-active');
        $target.classList.toggle('is-active');

      });
    });
  }

  $("#results tr").on("click", function(){
    let val = $(this).attr('data-view');
    location.href= val;
    return false;
  });

  let expanders = document.querySelectorAll("*[data-expand]");
  expanders.forEach(function ($el){
    new Expandable($el);
  })

  let modalToggles = document.querySelectorAll('[data-modal-target]')
  modalToggles.forEach(function (el){
    new ModalToggle(el);
  });


  let filterComponents = document.querySelectorAll('.filter-links')
  filterComponents.forEach( function(el, i){

    let props = {
      props:{
      }
    };

    for(let key in el.dataset){
      props.props.links = JSON.parse(el.dataset.links)
      props.props.mindate = el.dataset.mindate
      props.props.maxdate = el.dataset.maxdate
      props.props.startdate = el.dataset.startdate
      props.props.enddate = el.dataset.enddate
      props.props.projects = JSON.parse(el.dataset.projects)
    }

    const app = new Vue(
      {
        el: el,
        render: function(h){
          return h(FilterLinks, props);
        }
      }
    )
  });

  let moduleConfigs = document.querySelectorAll('#module-configuration')
  moduleConfigs.forEach( function(el, i){
    let props = {
      props: {
        init: el.dataset.init
      }
    };

    const app = new Vue(
      {
        el: el,
        render: function(h){
          return h(ModuleConfiguration, props);
        }
      }
    )
  })

  let demographicOptions = document.querySelectorAll('#demographic-options')
  demographicOptions.forEach( function (el, i) {
    let props = {
      props: {
        fields: JSON.parse(el.dataset.fields),
        required: JSON.parse(el.dataset.required),
        available: ['country', 'postcode', 'gender', 'age', 'ethnicity', 'disability']
      }
    }
    console.log(props)
    const app = new Vue({
      el: el,
      render: function (h) {
        return h(DemographicOptions, props)
      }
    })
  })


});