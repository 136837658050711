import Vue from 'vue';
const _ = require('lodash');

export default {
  template: "\
    <div class='membership-form'> \
      <fieldset> \
      <legend>Enter email</legend> \
      <input type='text' class='input' v-on:keyup='onKeyup' v-model='search'> \
      <ul class='candidates'> \
        <li v-for='candidate in candidates' class='candidate'><span class=\"email\">{{ candidate.email }}</span><a href='#' @click.prevent='addMember(candidate)'>Add</a></li> \
        <li v-if='this.hasNoCandidates && isValidEmail( search )' class='candidate'><span class='email'>Not found</span></li>\
      </ul> \
      </fieldset> \
    </div> \
  ",
  data: function(){
    return {
      search: '',
      candidates: [],
      hasNoCandidates: false
    }
  },
  props: ['url'],
  methods: {
    onKeyup: _.debounce( function(){
        if(this.search == ""){
          this.loadCandidates({data: {results:[]}})
        } else {
          this.$http.post(this.url,
              { search: this.search },
              { headers: {'X-CSRF-Token': this.$csrfToken} }
          ).then(this.loadCandidates);
        }

    }, 500),
    addMember: function(candidate){
      let candidateData;
      if(candidate == this.search){
        candidateData = {is_new: true, email: candidate}
      } else {
        candidateData = {is_new: false, id: candidate.id }
      }
      this.$emit('addmember', candidateData);
      this.search = "";
      this.candidates = [];
    }
  },
  created: function(){
    this.loadCandidates = function(response){
      this.hasNoCandidates = response.data.results.length > 0 ? false : true;
      this.candidates = response.data.results;
    }.bind(this)

    this.isValidEmail = function(email){
      return email.match(/^([\w\.%\+\-]+)@([\w\-]+\.)+([\w]{2,})$/i)
    }
  }
}