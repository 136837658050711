export function bind(fn, me) {
  return function(){ return fn.apply(me, arguments); };
};

export function request_animation_polyfill() {
  (function() {
    var lastTime = 0;
    var vendors = ['ms', 'moz', 'webkit', 'o'];
    for(var x = 0; x < vendors.length && !window.requestAnimationFrame; ++x) {
      window.requestAnimationFrame = window[vendors[x]+'RequestAnimationFrame'];
      window.cancelAnimationFrame = window[vendors[x]+'CancelAnimationFrame']
                     || window[vendors[x]+'CancelRequestAnimationFrame'];
    }

    if (!window.requestAnimationFrame)
      window.requestAnimationFrame = function(callback, element) {
        var currTime = new Date().getTime();
        var timeToCall = Math.max(0, 16 - (currTime - lastTime));
        var id = window.setTimeout(function() { callback(currTime + timeToCall); },
          timeToCall);
        lastTime = currTime + timeToCall;
        return id;
      };

    if (!window.cancelAnimationFrame)
      window.cancelAnimationFrame = function(id) {
        clearTimeout(id);
      };
  }());
};

export function supports_svg() {
  return document.implementation.hasFeature('http://www.w3.org/TR/SVG11/feature#Image', '1.1');
};

export function supports_rgba() {
  var style = document.createElement('a').style;
  style.cssText = 'background-color:rgba(150,255,150,.5)';
  return ('' + style.backgroundColor).indexOf('rgba') > -1;
};
