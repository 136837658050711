/* eslint no-console:0 */
// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.
//
// To reference this file, add <%= javascript_pack_tag 'application' %> to the appropriate
// layout file, like app/views/layouts/application.html.erb
import "@babel/polyfill";
import 'normalize.css';
import '@fortawesome/fontawesome-free/scss/fontawesome.scss';
import '@fortawesome/fontawesome-free/scss/solid.scss';
import 'scss/eveleth.css';
import 'scss/webfonts/384AEE_0_0.woff2';
import 'scss/webfonts/384AEE_0_0.eot';
import 'scss/webfonts/384AEE_0_0.woff';
import 'scss/webfonts/384AEE_0_0.ttf';
import 'bulma';
import 'bulma-extensions';
import 'd3';
import 'scss/application.scss';
import 'scss/pages/pulse.scss';
import 'js/common/main.js';
import 'js/common/signup.js';
import 'vue';
import 'es6-promise';
import 'axios';
import 'nodelist-foreach-polyfill';
import 'js/superadmin/organisation.js';
import 'js/superadmin/user-app.js';
import 'js/superadmin/editor.js';
import 'js/admin/projects.js';
import 'js/admin/results.js';
import 'js/parallax.js';
import 'js/common/expandable.js';
import 'webpack-merge';
import 'images/demo.png';
import 'images/happy-city-index-logo.png';
import 'images/happy-city-index-logo-cy.png';
import 'images/hc_pulse_logo.svg';
import 'images/pulse.svg';
import 'images/icons.png';
import 'images/parallax/illustration-welcome-foreground.svg';
import 'images/parallax/illustration-welcome-middleground.svg';
import 'images/parallax/illustration-welcome-background.svg';
import 'images/parallax/illustration-be-foreground.png';
import 'images/parallax/illustration-be-middleground.png';
import 'images/parallax/illustration-be-background.png';
import 'images/parallax/illustration-do-foreground.png';
import 'images/parallax/illustration-do-middleground.png';
import 'images/parallax/illustration-do-background.png';
import 'images/parallax/illustration-connect-foreground.png';
import 'images/parallax/illustration-connect-middleground.png';
import 'images/parallax/illustration-connect-background.png';
import 'images/parallax/illustration-results.svg';
import 'images/home-splash.jpg';
import 'images/just_skylines.svg';
import 'images/tc_logo.gif';
import 'clipboard';
import 'simple-lightbox';

require("trix")
require("@rails/actiontext")