<template>
  <div class='demographic-options'>
    <div class="field">
      <input type="checkbox" style='display:none' v-for='field in available' :name='toName("fields",field)' :id='toID("fields", field)' v-model='localFields[field]'/>
      <input type="checkbox" style='display:none' v-for='field in available' :name='toName("required",field)' :id='toID("required", field)' v-model='localRequired[field]'/>
      <label>Show fields </label>
        <div class="control" v-for='field in available'>
        <div class="checkbox">
          <input type="checkbox" :value='field' :name='toName("cfields",field)' :id='toID("cfields", field)' v-model='localFields[field]' :disabled='isDisabled(disabledFields, field)'/>
          <label :for="toID('cfields',field)">{{ field | capitalize }}</label>
        </div>
      </div>
    </div>

    <div class="field">
      <label>Required fields </label>
      <div class="control" v-for='field in available'>
        <div class="checkbox">
          <input type="checkbox" :value='field' :name='toName("crequired",field)' :id='toID("crequired", field)' v-model='localRequired[field]' :disabled='isDisabled(disabledRequired, field)'/>
          <label :for="toID('crequired',field)">{{ field | capitalize }}</label>
        </div>
      </div>
    </div>

  </div>
</template>
<script>
export default {
  props: {
    fields: {type: Array, default: () => {[]}},
    required: {type: Array, default: () => {[]}},
    available: {type: Array, default: () => {[]}}
  },
  filters: {
    capitalize (value) {
      let first = value.charAt(0).toUpperCase()
      return first + value.substr(1)
    }
  },
  data () {
    return {
      localFields: {},
      localRequired: {},
      disabledFields: {},
      disabledRequired: {}
    }
  },
  methods: {
    updateDisabledStates () {
      this.disabledFields = {}
      this.disabledRequired = {}
      for(let field in this.localRequired){
        if (this.localRequired[field] === true) {
          this.disabledFields[field] = true
        }
      }

      for(let field in this.localFields){
        if (this.localFields[field] === false) {
          this.disabledRequired[field] = true
        }
      }
    },
    isDisabled (group, field) {
      if (typeof group[field] !== 'undefined'){
        return true
      } else {
        return false
      }
    },
    toName (prefix, field) {
      return `demographics_${prefix}[${field}]`
    },
    toID (prefix, field) {
      return `demographics_${prefix}_${field}`
    }
  },
  watch: {
    localFields: {
      handler: function (fields) {
        this.updateDisabledStates();
      },
      deep: true
    },
    localRequired: {
      handler: function (required) {
        this.updateDisabledStates();
      },
      deep: true
    }
  },
  created () {
    let fields = {}
    let required = {}
    for(let key of this.available){
      fields[key] = this.fields.indexOf(key) !== -1
      required[key] = this.required.indexOf(key) !== -1
    }
    this.localFields = fields
    this.localRequired = required
    this.updateDisabledStates();
  }
}
</script>
<style lang='scss' scoped>
  .demographic-options {
    .checkbox{
      min-width: 0;
      input[disabled] {
        & + label::before {
          border: 2px solid #ccc;
        }
        & + label {
          color: #ccc;
        }
      }
    }
  }
</style>