import * as d3 from "d3";
import 'nodelist-foreach-polyfill';

(function(){

  $(document).on('ready', init);

  var colours = [
  "#87d341",
  "#d3687a",
  "#3f78d3",
  "#d239d3",
  "#d0d354",
  "#2dcdd3",
  "#57d37d",
  "#d31930"
  ]

  function init(){

    $('.tabs ul li a').each(function(i, el){
      var target = $(el).data('target');
      if(!$(el).parents('li').hasClass('is-active')){
        $(target).addClass('is-hidden')
      }
    })

    $('.tabs ul li a').on('click', function(){
      $('.tab-content').addClass('is-hidden')
      $('.tabs ul li').removeClass('is-active');
      var target = $(this).data('target');
      $(target).removeClass('is-hidden')
      $(this).parents('li').addClass('is-active');

    });

    $('td.toggle-demographic').on('click', function(){
      $(this).find('.demographic-breakdown').toggleClass('hidden')
      $(this).find('i').toggleClass('fa-plus fa-minus')
      $(this).parent('tr').toggleClass('active')
    })

    if( $('#results-report').length == 0){
      return false;
    }

    let demoStatsWidth = $('.demographics.stats').width()
    let width = demoStatsWidth - 100;
    var barHeight = 30;
    makeLineChart('#response-chart', responseTimeSeries, startDate, endDate, 880, 300)
    makeBarChart(".gender.report .chart", genders, totalEntries, width, barHeight);
    makeBarChart(".ages.report .chart", ages, totalEntries, width, barHeight);
    makeBarChart(".ethnicities.report .chart", ethnicities, totalEntries, width, barHeight, 'short_label');
    makeBarChart(".disability.report .chart", disabilities, totalEntries, width, barHeight);

  }

  function makeLineChart(selector, data, startDate, endDate, width, height) {
    var maxData = maxValue(data);
    var padding = 45;
    var drawableWidth = width - (padding*2)
    var drawableHeight = height - (padding * 2)

    var xspacing;
    if(data.length > 1){
      xspacing = (drawableWidth / (data.length-1));
    } else {
      xspacing = (drawableWidth / (data.length));
    }

    var axisXScale = d3.scaleTime()
        .domain([startDate, endDate])
        .range([0, drawableWidth]);

    var y = d3.scaleLinear()
        .domain([0, maxData])
        .range([0, drawableHeight]);

    var axisYScale = d3.scaleLinear()
        .domain([0, maxData])
        .range([drawableHeight, 0]);

    var numDays = ((endDate - startDate)/ (1000*60*60*24))
    var interval;
    var timeFormat
    if(numDays >= 72) {
      interval = d3.timeMonth.every(1)
      timeFormat = '%b %d %Y'
    }
    if(numDays < 72){
      interval = d3.timeWeek.every(2)
      timeFormat = '%b %d %Y'
    }
    if(numDays < 32){
      interval = d3.timeDay.every(1)
      timeFormat = '%b %d'
    }

    var lineFunc =  d3.line()
                    .x(function(d, i){ return padding + axisXScale.nice(interval)(new Date(d.label))})
                    .y(function(d, i){ return padding + axisYScale.nice(interval)(d.value)})

    var chart = d3.select(selector)
        .attr("width", width)
        .attr("height", height+30);

    var rect = chart.append('rect')
    .attr('width', width)
    .attr('height', height+30)
    .attr('fill', '#efefef')

    var drawableArea = chart.append('rect')
    .attr('x', padding-10)
    .attr('y', padding-5)
    .attr('width', drawableWidth+20)
    .attr('height', drawableHeight)
    .attr('fill', 'none')
    .attr('stroke', '#dedede')

    var g = chart.selectAll("g")
      .data(data)
      .enter().append('g')

    var path = chart.append('path')
      .attr('d', lineFunc(data))
      .attr('stroke', 'red')
      .attr('fill', 'none')

    var dot = g.append('circle')
    .attr('fill', 'green')
    .attr('cx', function(d,i){ return padding + axisXScale.nice(interval)(new Date(d.label))  })
    .attr('cy', function(d,i){ return padding + axisYScale.nice(interval)(d.value) })
    .attr('r', '3')

    var legend = chart.append('g')
    .attr('class', 'legend')
    .data(data)

    var axis = d3.axisLeft(axisYScale);
    chart.append('g')
    .attr('transform', "translate(" + (padding-10) + ", " + padding  + ")")
    .call(axis)

    var dateTicks = d3.axisBottom(axisXScale.nice(interval)).tickFormat(d3.timeFormat(timeFormat), '').ticks(interval)

    chart.append('g')
    .attr("class", "grid")
    .attr("transform", "translate(" + padding + "," + (drawableHeight + padding) + ")")
    .call(dateTicks)
    .selectAll("text")
        .style("text-anchor", "end")
        .attr("dx", "-.8em")
        .attr("dy", ".15em")
        .attr("transform", "rotate(-45)");

    var gridLines = chart.append('g')
    .attr('class', 'gridlines')
    .attr("transform", "translate(" + padding + "," + (drawableHeight + padding) + ")")

    var gridLineFunc = function(d){
      return axisXScale(d)
    }

    if (numDays > 31){
      gridLines.selectAll('g.gridlines')
      .data(axisXScale.ticks(interval))
      .enter()
      .append('line')
      .attr("class", "grid")
      .attr("x1", gridLineFunc )
      .attr("y1", 0 )
      .attr("x2", gridLineFunc )
      .attr("y2", -drawableHeight)
      .attr('fill', 'none')
      .attr('stroke', '#000000')
    }

  }

  function makeBarChart(selector, data, totalEntries, width, barHeight, labelProperty){
    if(typeof labelProperty === 'undefined'){
      labelProperty = 'label';
    }
    var maxData = maxValue(data);

    var x = d3.scaleLinear()
        .domain([0, maxData])
        .range([0, width * 0.5]);

    var chart = d3.select(selector)
        .attr("width", width)
        .attr("height", barHeight * data.length);

    var bar = chart.selectAll("g")
        .data(data)
      .enter().append("g")
        .attr("transform", function(d, i) { return "translate(0," + i * barHeight + ")"; });

    bar.append("rect")
        .attr("width", function(d){ return x(d.value)})
        .attr("height", barHeight - 1)
        .attr("style", function(d, i){ return "fill:" + '#0066b3' + ";"})

    bar.append("text")
        .attr("x", function(d) { return x(d.value) + 5; })
        .attr("y", 5)
        .attr("dy", "1em")
        .text(function(d) { return d[labelProperty]; });

    bar.append("text")
        .attr("x", function(d) { return width - 90; })
        .attr('title', function(d){return d.label})
        .attr("y", 5)
        .attr("dy", "1em")
        .text(function(d) { return resultPercent(d.value, totalEntries); });

  }

  function sumValues(data){
    var max = 0;
    data.forEach(function(el){
      max += el.value;
    });
    return max;
  }

  function maxValue(data){
    var max = 0;
    data.forEach(function(el){
      if(el.value > max){
        max = el.value
      }
    });
    return max;
  }

  function resultPercent(value, totalEntries){
    if (totalEntries === 0) {
      return " (0)";
    }
    return " (" + value + " / " + percentage(value, totalEntries)  + "%)";
  }

  function percentage(val, total){
    return ((val/total)*100).toFixed(2);
  }

})();