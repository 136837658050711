import Vue from 'vue';

export default {
  template:"\
    <div class='user-list'> \
      <a v-if='this.currentPage > 1' href='#' v-on:click.prevent='prev'><< Prev</a> \
      <span href=''>Page {{ currentPage }} of {{ paging.total_pages }}</span> \
      <a v-if='this.currentPage < this.paging.total_pages' href='#' v-on:click.prevent='next'>Next >></a> \
      <table> \
        <thead> \
          <tr> \
            <th>User</th> \
            <th>Joined</th> \
            <th v-if='superadmin === true'>Confirmed</th> \
            <th v-if='superadmin === true'>Last sign in</th> \
            <th>Options</th> \
          </tr> \
        </thead> \
        <tr v-for='user in users'> \
          <td> \
            {{ user.email }} \
          </td> \
          <td>{{ user.created_at }}</td> \
          <td v-if='superadmin === true'>{{ user.confirmed_at }}</td> \
          <td v-if='superadmin === true'>{{ user.last_sign_in_at }}</td> \
          <td> \
            <a v-bind:href='userUrl(user.id)' class='button button-primary'>View</a> \
            <a v-bind:href='becomeUrl(user.id)' v-if='become' class='button button-primary'>Become</a> \
          </td> \
        </tr>\
      </table> \
    </div>",
  props: ['users', 'url', 'paging', 'become', 'superadmin'],
  methods: {
    viewUser: function(id){
      document.location.href = this.url + "/" + id;
    },
    userUrl: function(id){
      return this.url + "/" + id
    },
    becomeUrl: function(id){
      return this.userUrl(id) + '/become'
    },
    next: function(){
      if(this.currentPage < this.paging.total_pages){
        this.currentPage += 1;
        this.getUsers(this.currentPage);
      }
    },
    prev: function(){
      if(this.currentPage > 1){
        this.currentPage -= 1;
        this.getUsers(this.currentPage);
      }
    }
  },
  data: function(){
    return {
      currentPage: 1
    }
  },
  created: function(){
    this.getUsers = function(page){
      this.$http.get(this.url + ".json?page=" + page,
            { headers: {'X-CSRF-Token': this.$csrfToken} }
      ).then(this.loadUsers);
    }.bind(this);

    this.loadUsers = function(response){
      this.$emit('onresults', response.data)
    }.bind(this)
  }
}