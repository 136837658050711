import Vue from 'vue';
require('es6-promise').polyfill();
const axios = require('axios');
import MembershipList from './membership-list';
import MembershipForm from './membership-form';

$(document).on('ready', init);

function init() {
  Vue.prototype.$csrfToken = document.querySelectorAll('meta[name="csrf-token"]')[0].content;
  if($('#orgdashboard').length > 0){
    new Vue({
      el: '#orgdashboard',
      components: {
        'membership-list': MembershipList,
        'membership-form': MembershipForm
      },
      methods: {
        onAddMembership: function(candidate){
          axios.post('/system/memberships', { user: candidate, organisation_id: this.organisation.id}, { headers: {'X-CSRF-Token': this.$csrfToken} }
          ).then(
            function(response){
              if(typeof response.data.memberships != "undefined"){
               this.memberships = response.data.memberships;
              }
            }.bind(this)
          ).catch(
            function(err){
            }
          )
        },
        onDeleteMembership: function(id){
          if(confirm('Are you sure? This will permanently delete the membership, but not the user.') == false){
            return;
          }
          axios(
            {
              method: 'delete',
              url: '/system/memberships/' + id + ".json",
              headers: {'X-CSRF-Token': this.$csrfToken}
            }
          ).then(
            function(response){
              if(typeof response.data.memberships != "undefined"){
                this.memberships = response.data.memberships;
              }
            }.bind(this)
          )
        },
        onEditMembership: function(id){
          document.location.href = "/system/memberships/" + id + "/edit"
        },
        onBecomeUser: function(id){
          document.location.href = "/system/users/" + id + "/become"
        }
      },
      data: {
        organisation: orgData.organisation,
        memberships: orgData.memberships,
        showModal: false
      },
      created: ()=> {

      }
    });
  }
}