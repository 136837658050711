import Vue from 'vue';
require('es6-promise').polyfill();
const axios = require('axios');
Vue.prototype.$http = axios;
import UserList from './user-list';
import Autocomplete from '../common/autocomplete';

$(document).on('ready', init);

function init() {
  Vue.prototype.$csrfToken = document.querySelectorAll('meta[name="csrf-token"]')[0].content;
  if($('#user-app').length > 0){
    new Vue({
      el: '#user-app',
      components: {
        'user-list': UserList,
        'autocomplete': Autocomplete
      },
      methods: {
        updateUsers: function(response){
          this.users = response.results
          this.paging = response.paging
        }
      },
      data: {
        users:userData.results,
        paging: userData.paging
      },
      created: ()=> {

      }
    });
  }
}