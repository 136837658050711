<template>
  <div class='filter-links'>
    <a @click.prevent='toggleFilters' class='button data-button export-button is-success'>Filter by date {{ filterBadge() }}</a>
    <a :href='urlWithDate(link.path)' v-for='link in links' class='button data-button export-button is-success'>{{ link.label }}</a>
    <a href='javascript:window.print()' class='button data-button export-button is-success'>Print</a>
    <div class="filters" v-if='showFilters'>
      <div class="field">
        <label for='fromdate'>From:</label>
        <datepicker :disabled-dates='disabledDatesFrom' :highlighted='highlightedStartDates' v-model="startDate"></datepicker>
      </div>
      <div class="field">
        <label for='todate'>To:</label>
        <datepicker :disabled-dates='disabledDatesTo' :highlighted='highlightedEndDates' v-model="endDate"></datepicker>
      </div>
      <div class="field">
        <a @click.prevent='applyFilters' class='apply-button button data-button is-danger'>Apply Filters</a>
        <a @click.prevent='clearFilters' class='apply-button button data-button is-danger'>Clear Filters</a>
      </div>
      <form ref='form' :action='url' method='post'>
        <input type="hidden" name='authenticity_token' :value='$csrfToken'>
        <input type="hidden" name='start' v-model='shortStartDate'/>
        <input type="hidden" name='end' v-model='shortEndDate'/>
        <input type="hidden" name="combine[]" v-for='project in projects' :value='project'>
      </form>
    </div>
  </div>
</template>
<script>
  import Datepicker from 'vuejs-datepicker';
  export default {
    props: ['links', 'mindate', 'maxdate', 'startdate', 'enddate', 'projects'],
    data () {
      return {
        showFilters: false,
        minDate: null,
        maxDate: null,
        startDate: null,
        endDate: null,
        highlightedStartDates: {dates:[]},
        highlightedEndDates: {dates:[]},
        disabledDatesFrom: {to: null, from: null},
        disabledDatesTo: {to: null, from: null},
        url: ''
      }
    },
    components: {
      'datepicker': Datepicker
    },
    computed: {
      shortStartDate () {
        return (this.startDate.toDateString())
      },
      shortEndDate () {
        return (this.endDate.toDateString())
      }
    },
    methods: {
      filterBadge () {
        let count = 0
        if (this.hasFilteredDate()){
          count++
        }
        if (count > 0) {
          return '('+count+')'
        } else {
          return ''
        }
      },
      hasFilteredDate () {
        let hasFiltered = false
        if (this.startDate.getTime() !== this.minDate.getTime()) {
          hasFiltered = true
        }
        if (this.endDate.getTime() !== this.maxDate.getTime()) {
          hasFiltered = true
        }
        return hasFiltered
      },
      formatDate (date) {
        return date.getFullYear() + '-' + (date.getMonth()-1) + '-' + date.getDate();
      },
      toggleFilters () {
        this.showFilters = !this.showFilters
      },
      applyFilters () {
        if(this.projects.length > 1){
          this.$refs.form.submit();
        } else {
          this.reloadWithFilters()
        }
      },
      clearFilters () {
        this.startDate = this.minDate
        this.endDate = this.maxDate
        if(this.projects.length > 1){
          this.$refs.form.submit();
        } else {
          this.reloadWithFilters()
        }
      },
      reloadWithFilters () {
        let loc = window.location.origin + window.location.pathname
        window.location.href = this.urlWithDate(loc)
      },
      urlWithDate (url) {
        url += '?start=' + encodeURIComponent(this.startDate.toDateString())
        url += '&end=' + encodeURIComponent(this.endDate.toDateString())
        return url
      }
    },
    created () {
      this.minDate   = new Date(this.mindate)
      this.maxDate   = new Date(this.maxdate)
      this.startDate = new Date(this.startdate);
      this.endDate   = new Date(this.enddate)
      this.highlightedStartDates.dates.push(this.minDate)
      this.highlightedEndDates.dates.push(this.maxDate)
      this.disabledDatesFrom = {
        to: this.minDate,
        from: new Date()
      }
      this.disabledDatesTo = {
        to: this.startDate,
        from: new Date()
      }
    },
    watch: {
      startDate (newDate, oldDate) {
        newDate.setDate(newDate.getDate())
        this.disabledDatesTo.to = newDate
      }
    }
  }
</script>
<style lang='scss' scoped>
  .filter-links {
    a + a {
      margin-left:0.5em;
    }
  }
  .filters {
    margin-top:1em;
    padding:1em;
    border:1px solid #ccc;
    display:flex;
  }
  .field {
    flex: 0 1 20%;
  }
  .apply-button {
    margin-top:1em;
  }
</style>
